import firebase from "utils/firebase";

export const register = async (username, password) => {
  const cred = await firebase
    .auth()
    .createUserWithEmailAndPassword(username, password);
  await cred.user.sendEmailVerification();
  return cred;
};

export const login = (username, password) => {
  return firebase.auth().signInWithEmailAndPassword(username, password);
};

export const retrieveUserId = () => {
  return firebase.auth().currentUser.uid;
};

export const isLoggedIn = () => {
  return firebase.auth().currentUser != null;
};

export const logout = () => {
  return firebase.auth().signOut();
};

export const forgotPassword = (email) => {
  return firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      return true;
    });
};
