import { add, remove, retrieve } from "utils/portfolio/data";

const requestData = async (isins) => {
  const body = JSON.stringify(isins);
  const response = await fetch("/api/funds/details", {
    method: "POST",
    body,
  });
  return await response.json();
};

const portfolio = {
  state: {
    isLoaded: false,
    data: [],
  },
  reducers: {
    addData(state, payload) {
      const newData = [...state.data, ...payload];
      return {
        isLoaded: true,
        data: newData,
      };
    },
    removeData(state, isin) {
      const newData = state.data.filter((item) => {
        return item.isin != isin;
      });
      return {
        isLoaded: true,
        data: newData,
      };
    },
  },
  effects: {
    async fetch() {
      try {
        const portfolioData = await retrieve();
        const data = await requestData(portfolioData.isins);
        this.addData(data);
      } catch (err) {
        console.log(err);
      }
    },
    async addRemoveFund({ isin, exists }, state) {
      try {
        if (exists) {
          const result = await remove(isin);
          if (result) {
            this.removeData(isin);
          }
        } else {
          const result = await add(isin);
          if (result) {
            const payload = await requestData([isin]);
            this.addData(payload);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};

export default portfolio;
