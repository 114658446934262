import firebase from "utils/firebase";
import { retrieveUserId } from "utils/auth/account";

const COLLECTION = "portfolio";

export const retrieve = async () => {
  const userId = retrieveUserId();
  const doc = await firebase
    .firestore()
    .collection(COLLECTION)
    .doc(userId)
    .get();
  const data = await doc.data();
  if (!data) {
    const initialData = { isins: [] };
    firebase.firestore().collection(COLLECTION).doc(userId).set(initialData);
    return initialData;
  }
  return data;
};

export const add = async (isin) => {
  const userId = retrieveUserId();
  const portfolioRef = firebase.firestore().collection(COLLECTION).doc(userId);
  const result = portfolioRef
    .update({
      isins: firebase.firestore.FieldValue.arrayUnion(isin),
    })
    .then(function () {
      return true;
    });

  return result;
};

export const remove = async (isin) => {
  const userId = retrieveUserId();
  const portfolioRef = firebase.firestore().collection(COLLECTION).doc(userId);
  const result = portfolioRef
    .update({
      isins: firebase.firestore.FieldValue.arrayRemove(isin),
    })
    .then(function () {
      return true;
    });

  return result;
};
