import React from "react";
import { Provider } from "react-redux";
import App from "next/app";
import Router from "next/router";
import NProgress from "nprogress";
import { version } from "utils/build";
import withRematch from "utils/rematch/withRematch";
import "themes/index.scss";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

class MyApp extends App {
  render() {
    const { Component, pageProps, reduxStore } = this.props;

    return (
      <Provider store={reduxStore}>
        <Component {...pageProps} />
      </Provider>
    );
  }
}

export default withRematch(MyApp);
