import { init } from "@rematch/core";
import portfolio from "utils/rematch/models/portfolio";

const defaultInitialState = {};

export const initializeStore = (initialState = defaultInitialState) =>
  init({
    models: {
      portfolio,
    },
    redux: {
      initialState,
    },
  });
